import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { Credentials, ICredentials } from '../interfaces/credentials';
import { StorageService } from './storage.service';
import { getCurrentLocale } from './i18n.service';

export type RestCache = "reload" | "no-store" | "no-cache" | "force-cache" | "default";

export abstract class RestService {

  private headers: Headers = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });
  private api: string;


  protected constructor() {
  }

  public setApi(api) {
    this.api = api;
  }

  public setAuthHeader(credentials: ICredentials) {
    if (credentials) {
      if (credentials?.accessToken != null || credentials?.jwt != null) {
        this.setHeader('Authorization', 'Bearer ' + credentials.accessToken);
        this.setHeader('locale', getCurrentLocale())
      } else {
        this.deleteHeader('Authorization');
      }
    }
  }

  public appendHeader(name, value) {
    this.headers.append(name, value);
  }

  public setHeader(name, value) {
    this.headers.set(name, value);
  }

  public deleteHeader(name) {
    this.headers.delete(name);
  }

  public read(url, data?, cache?: RestCache): Observable<any> {
    return this.fetch(this.request(url, 'GET', data, cache));
  }

  public create(url, data?): Observable<any> {
    return this.fetch(this.request(url, 'POST', data));
  }

  public update(url, data?): Observable<any> {
    return this.fetch(this.request(url, 'PUT', data));
  }

  public add(url, data?): Observable<any> {
    return this.fetch(this.request(url, 'PATCH', data));
  }

  public delete(url): Observable<any> {
    return this.fetch(this.request(url, 'DELETE', null));
  }

  private request(endPoint: string, method: string, data: any, cache: RestCache = "default", requestCredentials: RequestCredentials = 'omit') {

    method = method.toUpperCase();

    if (!this.api) {
      throw new Error('no api is set');
    }

    const credentials = new Credentials().deserialize(StorageService.get('credentials'));
    this.setAuthHeader(credentials);
    let api = this.api + endPoint;
    let init = {
      method,
      body: null,
      headers: this.headers,
      cache,
      credentials: requestCredentials
    };
    if (data && method !== 'GET') {
      init.body = JSON.stringify(data);
    }
    if (data && method === 'GET') {
      const queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
      api = api + "?" + queryString;
    }

    return new Request(api, init)
  }

  private fetch(request) {
    const response$ = new Subject();
    fetch(request).then(response => {
      response.text().then(text => {
        let data;
        try {
          data = JSON.parse(text);
        } catch (err) {
          data = text;
        }
        if (response.ok) {
          response$.next(data);
          response$.complete();
        } else {
          response$.error(data);
          response$.complete();
        }

      }
      )

    }, error => {
      response$.error(error.json);
      response$.complete();
    });
    return response$.asObservable();
  }

}
